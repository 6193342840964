<template>
  <div class="container">
    <br />
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ name: 'LandingPage' }">
        首頁
      </el-breadcrumb-item>
      <el-breadcrumb-item :to="{ name: 'Home' }">
        Dashboard
      </el-breadcrumb-item>
      <el-breadcrumb-item :to="{ name: 'privateLessons' }">
        家教課
      </el-breadcrumb-item>
      <el-breadcrumb-item>Progress Report Email</el-breadcrumb-item>
    </el-breadcrumb>
    <br />
    <el-divider />
    <div>
      <h1>Progress Report Email</h1>
    </div>
    <hr class="separate-line" />
    <Compose
      :mailContent="mailContent"
      :subject="subject"
      :selected_receivers="selected_receivers"
      :selected_cc_receivers="selected_cc_receivers"
      :receiver_options="receiver_options"
      :templateSelectable="false"
    />
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */

import mailApi from "@/apis/mail";
import Compose from "./Compose";

export default {
  metaInfo() {
    return {
      title: "Progress Report Email"
    };
  },

  components: {
    Compose
  },

  mixins: [],

  props: [],
  data() {
    return {
      mailContent: "",
      subject: "",
      selected_receivers: [],
      selected_cc_receivers: [],
      receiver_options: []
    };
  },
  computed: {},
  watch: {},

  async created() {
    const mail = await mailApi.getProgressReprotMailContent({
      class_progress_report_id: this.$route.query.class_progress_report_id,
      student_progress_report_id: this.$route.query.student_progress_report_id,
      target: this.$route.query.target
    });
    this.mailContent = mail.mail_content;
    this.selected_receivers = mail.selected_receivers;
    this.selected_cc_receivers = mail.selected_cc_receivers;
    this.receiver_options = mail.receiver_options;
    this.subject = mail.subject;
  },

  methods: {}
};
</script>

<style scoped></style>
