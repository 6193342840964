<template>
  <ckeditor
    @ready="onReady"
    :config="editorConfig"
    :value="value"
    @input="value => $emit('input', value)"
    :editor-url="'https://cdn.ckeditor.com/4.15.0/full-all/ckeditor.js'"
  />
</template>

<script>
import JwtService from "@/common/jwt.service";

export default {
  props: {
    value: {
      type: String,
      default: ""
    },
    testType: {
      type: String,
      default: "sat"
    },
    height: {
      type: String,
      default: "auto"
    },
    enableQuestionNumStyle: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    token() {
      return `Bearer ${JwtService.getToken()}`;
    },
    postUrl() {
      return process.env.VUE_APP_BACKEND_URL + "document";
    },
    toolbarGroups() {
      if (this.enableQuestionNumStyle) {
        return [
	      	{ name: "styles", groups: ["styles"] },
	      	"/",
	      	{ name: "basicstyles", groups: [ "basicstyles", "cleanup" ] },
	      	{ name: "paragraph", groups: [ "list", "indent", "blocks", "align", "bidi", "paragraph" ] },
	      	{ name: "links", groups: [ "links" ] },
	      	{ name: "insert", groups: [ "insert" ] }
        ];
      } else {
        return [
	      	{ name: "basicstyles", groups: [ "basicstyles", "cleanup" ] },
	      	{ name: "paragraph", groups: [ "list", "indent", "blocks", "align", "bidi", "paragraph" ] },
	      	{ name: "links", groups: [ "links" ] },
	      	{ name: "insert", groups: [ "insert" ] }
        ];
      }
    },
    editorConfig() {
      return {
        versionCheck: false,
        height: this.height,
        toolbarGroups: this.toolbarGroups,
        removeButtons: "Save,NewPage,ExportPdf,Preview,Print,Templates,Cut,Copy,Paste,PasteText,PasteFromWord,SelectAll,Scayt,Form,Checkbox,Radio,TextField,Textarea,Select,Button,ImageButton,HiddenField,Blockquote,CreateDiv,BidiLtr,BidiRtl,Language,Anchor,Flash,PageBreak,Iframe,Format,Maximize,ShowBlocks,About,Format,Font,FontSize",
        stylesSet: [{
          name : "Question Number",
          element : "span",
          attributes: { "class": `${this.testType.toLowerCase()}QuestionNumber` },
          styles:
            this.testType.toLowerCase() === "sat" ?
              { color: "#fff", background: "#000", margin: "0px 4px", padding: "0px 4px", "border-radius": "4px" } :
              { margin: "0px 4px", padding: "0px 4px",border: "1px solid #000" }
        }],
        allowedContent: true,
        filebrowserImageUploadUrl: this.postUrl
      };
    }
  },
  methods: {
    onReady(ckeditor) {
      ckeditor.on("fileUploadRequest", e => {
        const fileLoader = e.data.fileLoader;
        const xhr = fileLoader.xhr;
        let formData = new FormData();
        formData.append("type", "img");
        formData.append("document", fileLoader.file);
        xhr.open("POST", fileLoader.uploadUrl, true);
        xhr.setRequestHeader("Authorization", this.token);
        xhr.send(formData);
        e.stop();
      });

      ckeditor.on("fileUploadResponse", e => {
        const fileLoader = e.data.fileLoader;
        const xhr = fileLoader.xhr;
        const response = JSON.parse(xhr.responseText);
        e.data.url = response.document.url;
        e.stop();
      });
    }
  }
};
</script>
