<template>
  <div class="mail">
    <template v-if="isFinished">
      <el-alert v-if="model.send_at" title="已儲存" type="success" show-icon :closable="false">
        <div>
          本郵件將在
          <b>{{ model.send_at }} ({{ showDay(model.send_at) }})</b>
          發送到對方信箱
        </div>
      </el-alert>
      <el-alert v-else title="已發送" type="success" show-icon :closable="false"> </el-alert>
      <div class="mt-3">
        <router-link
          class="mr-3"
          :to="{
            name: 'EmailList'
          }"
        >
          <el-button type="success">
            查看郵件列表
          </el-button>
        </router-link>
        <el-button @click="isFinished = false">
          再寫一封
        </el-button>
      </div>
    </template>
    <template v-else>
      <el-dialog
        :close-on-click-modal="false"
        title="定時發送"
        :visible.sync="showModal"
        width="50%"
      >
        <div>
          <el-date-picker
            style="width: 100%"
            v-model="model.send_at"
            type="datetime"
            placeholder="选择日期时间"
            default-time="12:00:00"
            value-format="yyyy-MM-dd HH:mm:ss"
            :picker-options="pickerOptions"
          >
          </el-date-picker>
          <div v-if="model.send_at" class="text-center mt-2">
            本郵件將在
            <b>{{ model.send_at }} ({{ showDay(model.send_at) }})</b>
            發送到對方信箱
          </div>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="showModal = false">取 消</el-button>

          <el-button
            v-if="emailId"
            type="primary"
            @click="updateScheduledMailCompose()"
            >确 定</el-button
          >
          <el-button v-else type="primary" @click="sendScheduledMailCompose()"
            >确 定</el-button
          >
        </span>
      </el-dialog>
      <el-form
        :model="model"
        :rules="rules"
        ref="form"
        :validate-on-rule-change="false"
        @keyup.enter.native="handleLogin()"
        label-width="130px"
        class="mail-form"
      >
        <el-form-item align="left">
          <div>
            <el-button type="primary" size="small" @click="submit()">
              立刻發送
            </el-button>
            <el-button
              type="primary"
              size="small"
              @click="checkEmail()"
              v-if="!emailId"
            >
              定時發送
            </el-button>
            <el-button type="primary" size="small" @click="cancel()">
              {{ $t("email.cancel") }}
            </el-button>
          </div>
          <div v-if="emailId">
            本郵件將在
            <b>{{ model.send_at }} ({{ showDay(model.send_at) }})</b>
            發送到對方信箱。
            <b
              class="text-success"
              style="cursor: pointer;"
              @click="checkEmail()"
              >點擊修改</b
            >
          </div>
        </el-form-item>
        <el-form-item prop="sender" label="寄件人">
          <el-input
            v-model="model.sender"
            prefix-icon="el-icon-message"
            :disabled="true"
          ></el-input>
        </el-form-item>
        <el-form-item prop="users" label="收件人">
          <el-select
            v-model="model.users"
            multiple
            clearable
            filterable
            remote
            reserve-keyword
            placeholder="請輸入收件人"
            :remote-method="getReceiverOptions"
            :loading="loading"
            style="display:block;"
          >
            <el-option
              v-for="item in receiverOptions"
              :key="item.email"
              :label="item.name + ' (' + item.email + ')'"
              :value="item.email"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="cc_users" label="CC" v-show="model.is_cc">
          <el-select
            v-model="model.cc_users"
            multiple
            clearable
            filterable
            remote
            reserve-keyword
            placeholder="請輸入CC收件人"
            :remote-method="getReceiverOptions"
            :loading="loading"
            style="display:block;"
          >
            <el-option
              v-for="item in receiverOptions"
              :key="item.email"
              :label="item.name + ' (' + item.email + ')'"
              :value="item.email"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="bcc_users" label="BCC" v-show="model.is_bcc">
          <el-select
            v-model="model.bcc_users"
            multiple
            clearable
            filterable
            remote
            reserve-keyword
            placeholder="請輸入BCC收件人"
            :remote-method="getReceiverOptions"
            :loading="loading"
            style="display:block;"
          >
            <el-option
              v-for="item in receiverOptions"
              :key="item.email"
              :label="item.name + ' (' + item.email + ')'"
              :value="item.email"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="">
          <div style="line-height: 15px;color: #6f747e;">
            <small v-if="model.is_separate">
              每個人將收到單獨發給他/她的郵件。
            </small>
          </div>
          <el-checkbox v-model="model.is_separate">
            分別發送
            <el-tooltip
              class="item"
              effect="light"
              content="會對多個人一對一發送。每個人將收到單獨發給他/她的郵件。"
              placement="top"
            >
              <el-button type="text">
                <i class="fas fa-question-circle"></i>
              </el-button>
            </el-tooltip>
          </el-checkbox>
          <el-checkbox v-model="model.is_cc">添加抄送CC</el-checkbox>
          <el-checkbox v-model="model.is_bcc">添加密送BCC</el-checkbox>
        </el-form-item>
        <el-form-item prop="subject" label="標題">
          <el-input v-model="model.subject"></el-input>
        </el-form-item>

        <el-form-item
          v-if="templateSelectable == true"
          prop="template"
          label=""
        >
          <template slot="label">
            <div>
              內容樣板
              <router-link>
                <el-button type="success" size="mini">
                  <i class="fa fa-edit"></i>
                </el-button>
              </router-link>
            </div>
          </template>
          <el-select
            v-model="templateSelected"
            placeholder="選擇樣板"
            style="display:block;"
          >
            <el-option
              v-for="item in templateOptions"
              :key="item.id"
              :label="item.title"
              :value="item.content"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="content" label="內容">
          <Ckeditor
            :value="model.content"
            @input="
              content => {
                model = { ...model, content };
              }
            "
          />
          <el-input v-model="model.content" v-show="false"></el-input>
        </el-form-item>
      </el-form>
    </template>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import Ckeditor from "@/components/ckeditor/index.vue";
import mailApi from "@/apis/mail";
import formMixin from "@/mixins/form";
import moment from "moment";

export default {
  metaInfo() {
    return {
      title: "Email Compose - Ivy-Way Academy"
    };
  },

  components: {
    Ckeditor
  },

  mixins: [formMixin],

  props: {
    mailContent: String,
    subject: String,
    selected_receivers: Array,
    selected_cc_receivers: Array,
    receiver_options: Array,
    templateSelectable: { type: Boolean, default: true }
  },

  data() {
    return {
      isFinished: false,
      moment: moment,
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e7; //如果没有后面的-8.64e7就是不可以选择今天的
        }
      },
      showModal: false,
      loading: false,
      receiverOptions: [],
      templateOptions: [
        {
          id: 0,
          title: "empty",
          content: ""
        }
      ],
      templateSelected: "",
      model: {
        sender: "Ivy-Way Academy (contact@ivy-way.com)",
        users: [],
        cc_users: [],
        bcc_users: [],
        subject: "",
        content: "",
        is_separate: false,
        is_cc: false,
        is_bcc: false,
        send_at: ""
      },
      editorConfig: {
        toolbarGroups: [
          "basicstyles",
          "clipboard",
          "paragraph",
          "links",
          "styles",
          "colors",
          "mode",
          "table"
        ]
      }
    };
  },
  computed: {
    ...mapGetters("user", ["getProfile"]),
    rules() {
      return {
        users: [
          {
            type: "array",
            required: true,
            message: "此欄位為必填",
            trigger: "change"
          }
        ],
        subject: [
          {
            required: true,
            message: "此欄位為必填",
            trigger: "change"
          }
        ],
        content: [
          {
            required: true,
            message: "此欄位為必填",
            trigger: "change"
          }
        ]
      };
    },
    emailId() {
      return this.$route.params.id;
    }
  },
  watch: {
    templateSelected: function(value) {
      this.model.content = value.replace(
        /#{name}/g,
        this.getProfile.first_name
      );
    }
    // mailContent: {
    //   immediate: true,
    //   handler(mailContent) {
    //     this.model.content = mailContent;
    //   }
    // }
  },

  async mounted() {
    const templateRes = await mailApi.getTemplateOptions();

    this.templateOptions = this.templateOptions.concat(
      templateRes.mail_templates
    );

    if (this.emailId) {
      const res = await mailApi.getScheduledMail(this.$route.params.id);
      this.model = { ...res.scheduled_email };
    } else {
      this.model.content = this.mailContent;
      this.model.subject = this.subject;
      this.model.users = this.selected_receivers;
      this.receiverOptions = this.receiver_options;
      this.model.cc_users = this.selected_cc_receivers;
    }

    const { iso_code } = await this.$store.dispatch("ipGeo/getGeoLocation");
    let mail = "contact@ivy-way.com";
    if (iso_code === "CN") {
      mail = "china@ivy-way.com";
    }
    this.model = {
      ...this.model,
      sender: `Ivy-Way Academy (${mail})`
    };
  },

  methods: {
    showDay(date) {
      const weekArr = ["週日", "週一", "週二", "週三", "週四", "週五", "週六"];
      const weekDay = this.moment(date).format("d");
      return weekArr[weekDay];
    },
    async getReceiverOptions(keyword) {
      var res = await mailApi.getReceiverOptions(keyword);

      this.receiverOptions = res["users"];
    },
    async submit() {
      const valid = await this.validateForm(this.$refs["form"]);
      if (!valid) {
        return;
      } else {
        try {
          await mailApi.sendScheduledMailCompose({
            ...this.model,
            send_at: "正在發送"
          });

          await this.$message({
            message: this.$t("message.send_success"),
            type: "success"
          });
        } catch (e) {
          this.$message({
            showClose: true,
            message: e.response.data.message,
            type: "error"
          });
        }
        this.isFinished = true;
        // this.$router.push({ name: "Home" });
      }
    },
    async checkEmail() {
      const valid = await this.validateForm(this.$refs["form"]);
      if (!valid) {
        return;
      } else {
        this.showModal = true;
      }
    },
    async sendScheduledMailCompose() {
      try {
        await mailApi.sendScheduledMailCompose({ ...this.model });

        await this.$message({
          message: this.$t("message.set_success"),
          type: "success"
        });
        this.showModal = false;
      } catch (e) {
        this.$message({
          showClose: true,
          message: e.response.data.message,
          type: "error"
        });
      }
      this.isFinished = true;
      // this.$router.push({ name: "EmailList" });
    },
    async updateScheduledMailCompose() {
      try {
        await mailApi.updateEmail(this.emailId, { ...this.model });

        await this.$message({
          message: this.$t("message.set_success"),
          type: "success"
        });
        this.showModal = false;
      } catch (e) {
        this.$message({
          showClose: true,
          message: e.response.data.message,
          type: "error"
        });
      }
      this.isFinished = true;

      // this.$router.push({ name: "EmailList" });
    },
    cancel() {
      this.$router.push({ name: "EmailList" });
    }
  }
};
</script>

<style scoped>
.mail {
  height: 100%;
  padding: 1.5rem 2rem;
  background: #d0e7d9;
  overflow: auto;
}
::v-deep .el-form-item__label {
  margin-bottom: 0;
}
</style>
