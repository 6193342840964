import ApiService from "@/common/api.service";
import axios from "axios";
import JwtService from "@/common/jwt.service";

export default {
  async getReceiverOptions(keyword) {
    const res = await axios.get(
      process.env.VUE_APP_BACKEND_URL + "mail/compose",
      {
        params: { keyword: keyword },
        headers: {
          Authorization: `Bearer ${JwtService.getToken()}`
        }
      }
    );

    const { data } = res;

    return data;
  },
  sendMailCompose(data) {
    return ApiService.post("mail/compose", data);
  },
  getTemplateOptions() {
    return ApiService.query("mail/template");
  },
  getProgressReprotMailContent(data) {
    return ApiService.query("class-progress-report-mail", data);
  },
  sendScheduledMailCompose(data) {
    return ApiService.post("scheduled-mail", data);
  },
  getScheduledMails(data) {
    return ApiService.query("scheduled-mails", data);
  },
  getScheduledMail(id) {
    return ApiService.query(`scheduled-mail/${id}`);
  },
  updateEmail(id, data) {
    return ApiService.patch(`scheduled-mail/${id}`, data);
  },
  deleteEmail(id) {
    return ApiService.delete(`scheduled-mail/${id}`);
  }
};
